$(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
        localStorage['sidebarCollapsed'] = $('#sidebar').hasClass('active') ? true : false;
    });
});

moment.locale(abp.localization.currentLanguage.name);

abp.message.error = function (message = '', title = '') {
    swal({
        title: title,
        text: message,
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'Ok'
    });
};

abp.localization.defaultSourceName = getLocalizationSourceName();

var alreadyChecked = false;
var handleResponse = abp.ajax.handleResponse;
var handleNonAbpErrorResponse = abp.ajax.handleNonAbpErrorResponse;

function IsAuthenticated(jqXHR) {
    if (alreadyChecked || !abp.session.userId)
        return Promise.resolve();

    var promise = new Promise((resolve, reject) => {
        alreadyChecked = true;
        abp.ajax({
            url: '/Account/IsAuthenticated'
        }).then(data => {
            if (data !== true) {
                jqXHR.abort();
                swal.close();
                $('#LoginArea').modal('toggle');
                reject();
            }
            else {
                alreadyChecked = false;
                resolve();
            }
        });
    });

    return promise;
}

$.extend(abp.ajax, {
    handleResponse: function (data, userOptions, $dfd, jqXHR) {
        if (abp.session.userId && data) {
            if (data.success === false)
                IsAuthenticated(jqXHR).then(() => handleResponse(data, userOptions, $dfd, jqXHR));
            else
                handleResponse(data, userOptions, $dfd, jqXHR);
        }
        else {
            handleResponse(data, userOptions, $dfd, jqXHR);
        }
    },
    handleNonAbpErrorResponse: function (jqXHR, userOptions, $dfd) {
        switch (jqXHR.status) {
            case 400:
                IsAuthenticated(jqXHR);
                break;
            default:
                handleNonAbpErrorResponse(jqXHR, userOptions, $dfd);
                break;
        }
    }
});